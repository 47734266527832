<template>
  <div class="content" v-if="tournament">
    <div class="title-actions">
      <h5 class="title">{{ tournament.name }} - {{$t('teams')}} - {{ division.name }} </h5>
      <div class="btns">
        <b-form-input v-model="filter" :placeholder="$t('search')" autofocus />
        <b-button @click="$router.go(-1)">
          <i class="fa fa-arrow-left"></i>
        </b-button>
      </div>
    </div>
    <hr>
    <b-table striped bordered :items="teams" :fields="columns" :filter="filter">
      <div slot="order" class="text-right" slot-scope="row">{{ row.index + 1 }}</div>
      <div slot="name" slot-scope="row">
        <router-link :to="{ name: 'TeamBack', params: { lang: lang, console: console, teamId: row.item.id } }">{{ row.value }}</router-link>
      </div>
      <div slot="dts" slot-scope="row">
        <router-link v-if="row.item.dt" :to="{ name: 'PlayerHome', params: { lang: lang, console: console, playerSlug: row.item.dt.slug } }">DT {{ row.item.dt.nick }}</router-link>
        <router-link v-else-if="row.item.codt" :to="{ name: 'PlayerHome', params: { lang: lang, console: console, playerSlug: row.item.codt.slug } }">CODT {{ row.item.codt.nick }}</router-link>
      </div>
      <div v-if="user.type === 'SUPER' || user.type === 'ADMIN' || user.type === 'FULL'" slot="actions" slot-scope="row" class="text-center">
        <b-button variant="danger" @click="openModalDestroy(row.item)">
          <i class="fa fa-trash"></i>
        </b-button>
      </div>
    </b-table>
    <b-modal ref="modalDelete" :title="$t('confirmTitleDelete')" headerBgVariant="danger" headerTextVariant="light">
      <p>{{$t('confirmTextDelete')}}</p>
      <strong>{{ team.name }}</strong>
      <div slot="modal-footer">
        <b-button @click="$refs.modalDelete.hide()">{{$t('cancel')}}</b-button>
        <b-button variant="danger" @click="destroy" class="ml-3">{{$t('delete')}}</b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      filter: '',
      teams: [],
      columns: [
        { key: 'order', label: '#', sortable: false },
        { key: 'name', label: this.$t('name'), sortable: true },
        { key: 'dts', label: 'DT/CODT', sortable: true },
        { key: 'actions', label: '', sortable: false }
      ],
      tournament: null,
      division: null,
      team: {
        name: null
      }
    }
  },
  computed: {
    ...mapGetters([
      'lang',
      'console',
      'user'
    ])
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    destroy () {
      const tournamentId = this.$route.params.tournamentId
      const divisionId = this.$route.params.divisionId
      const path = `auth/${this.lang}/${this.console}/tournaments/${tournamentId}/divisions/${divisionId}/delete-team/${this.team.id}`
      this.$axios.delete(path).then(() => {
        this.teams = this.teams.filter(team => {
          return team.id !== this.team.id
        })
        this.$refs.modalDelete.hide()
        this.$toastr.success(this.$t('updatedInfo'), window.TITLE_SUCCESS)
      })
    },
    openModalDestroy (team) {
      this.team = team
      this.$refs.modalDelete.show()
    },
    fetchData () {
      const tournamentId = this.$route.params.tournamentId
      const divisionId = this.$route.params.divisionId
      const path = `auth/${this.lang}/${this.console}/tournament/${tournamentId}/division/${divisionId}/teams`
      this.$axios.get(path).then((response) => {
        this.teams = response.data.teams
        this.tournament = response.data.tournament
        this.division = response.data.division
        document.title = `${this.tournament.name} - ${this.$t('teams')} - ${this.division.name}`
      })
    }
  }
}
</script>
